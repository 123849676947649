/* Checks for change (like click) on element (input), and assigns input val to the elem's ng-model
* Not currently in use
*/
angular.module('sq.forms.validFileDirective', [])
.directive('validFile', function validFileDirective()  {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function linkFn(scope, el, attrs, ngModel) {
      el.bind('change', function bindFn() {
        scope.$evalAsync(function bindAction() {
          ngModel.$setViewValue(el.val());
        });
      });
    },
  };
});
